@charset "UTF-8";
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}
